<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 data_management">
            <div class="row">
                <div class="col-md-7 p-5 border_right left_con">
                    <div class="mb-30">
                        <div class="mn_titl">Demographic Import</div>
                        <div class="input-group custom_input_file">
                            <label tabindex="0" class="form-control cursor-pointer">
                                Choose File ...
                                <input type="file" class="d-none">
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="mn_titl">Demographic Export</div>
                        <div class="exp_line">
                            <div class="label_ex">Patient Set</div>
                            <input type="text" value="Every Patient" class="form-control">
                        </div>
                        <div class="exp_line">
                            <div class="label_ex">Export Template</div>
                            <input type="text" value="EMR Spec 5.0" class="form-control">
                        </div>
                        <div class="exp_line align-items-start">
                            <div class="label_ex">Export Categories</div>
                            <div class="row justify-content-center selact_box_part">
                                <div class="col-md-12">
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Select All</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Demographic</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Encounter Notes</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Files</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Measurements</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Medications</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Other Infos</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Preventions</label>
                                    </div>
                                    <div class="address_line">
                                        <input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel">
                                        <label>Screenings</label>
                                    </div>
                                </div>
                                <div class="col-md-12 text-end mt-30">
                                    <button class="comman_btn big_btn">Export</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 p-5 right_con disabled-form">
                    <div class="mb-30">
                        <div class="mn_titl">Linking Accounts</div>
                        <p class="cmn_contxt">Attach the Account Linking ID to share patient records in read-only format with the partner account</p>
                    </div>
                    <div class="mb-30">
                        <div class="mn_titl">Your Account Linking ID</div>
                        <div class="cmn_hdtxt">
                            <div>abcdeffffhgighgighgih123123123</div>
                            <div class="popup_link">Edit</div>
                        </div>
                    </div>
                    <div class="mb-30">
                        <div class="mn_titl ptlink_hd">Partner Account Linking ID <span class="cmn_contxt"> (Limited to maximum 5 accounts)</span></div>
                        <div class="cmn_hdtxt">
                            <div>abc_clinic</div>
                            <div class="popup_link">Disconnect</div>
                        </div>
                        <div class="cmn_hdtxt">
                            <div>xyz_clinic </div>
                            <div>
                                <span class="mr-30">requested to link</span>
                                <span class="popup_link mr-30">Confirm</span>
                                <span class="popup_link">Delete</span>
                            </div>
                        </div>
                        <div class="cmn_hdtxt">
                            <div>doremi_clinic </div>
                            <div>Requested</div>
                        </div>
                        <div class="plus_sign_row">+</div>
                        <div class="cmn_hdtxt exp_line ms-0">
                            <input type="text" placeholder="Enter Account Linking ID" class="form-control">
                            <div class="popup_link">Requested</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue';

export default {
    data() {
        return {
        }
    },
    components: {
        sidebar,
    }
};
</script>
