<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
			<div class="auditLogs-main-list" style="height:89%;">
				<div class="audit-head-box d-inline-block w-100">
					<div class="audit-prvd-daterange d-inline-block float-start mr-15">
						<div class="billing-date-calendar">
						<date-range-picker ref="picker" opens="center" :locale-data="{
							direction: 'ltr',
							format: 'yyyy-mm-dd',
							separator: ' - ',
							applyLabel: 'Apply',
							cancelLabel: 'Cancel',
							weekLabel: 'W',
							customRangeLabel: 'Custom Range',
							daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
							monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
							firstDay: 0
						}" singleDatePicker="range" v-model="dateRange" :dateRange="dateRange" :ranges="ranges" :minDate="minDate">
							<template v-slot:input="picker">
								{{ formatDateRange(picker.startDate) }} - {{ formatDateRange(picker.endDate) }}
							</template>
							<template v-slot:footer="{ rangeText, clickCancel, clickApply }">
								<div class="drp-buttons d-flex justify-content-between mb-2">
									<div class="selected-date-billig">
										<span class="start-date-blng" v-if="extractDates(rangeText, 'start') != ''">
											<label style="float: left;font-weight: 600;padding: 6px;">START</label>
											<input type="text" :value="extractDates(rangeText, 'start')" @change="handleDate('startDatePicker')" @blur="handleDate('startDatePicker')" ref="startDatePicker" @keyup="this.keyupdate('startDatePicker')"  id="startDatePicker">
										</span>
										<span class="end-date-blng" v-if="extractDates(rangeText, 'end') != ''">
											<label style="float: left;font-weight: 600;padding: 6px;">END</label>
											<input type="text" :value="extractDates(rangeText, 'end')" @change="handleDate('endDatePicker')" @blur="handleDate('endDatePicker')" ref="endDatePicker" @keyup="this.keyupdate('endDatePicker')"  id="endDatePicker">
										</span>
									</div>
									<div>
										<button class="cancelBtn btn btn-sm btn-secondary mt-4" type="button"
											@click="clickCancel">Cancel</button>
										<button class="applyBtn btn btn-sm btn-success mt-4" type="button"
											@click="clickApply">Apply</button>
									</div>
								</div>
							</template>
						</date-range-picker>
						</div>
					</div>
					<div class="audit-prvd-select d-inline-block float-start mr-15 mb-15">
						<CustomDropDown :options="this.provider_list" :defaultSelectedClass="defaultSelectedClass"
							:initialValue="this.filter.physician_text" v-model="filter.user_id"
							@item-selected="handleItemSelected" fieldName="user_id"></CustomDropDown>
					</div>
					<div class="audit-prvd-select d-inline-block float-start mr-15 mb-15">
						<CustomDropDown :options="typeDataSource" :defaultSelectedClass="defaultSelectedModuleClass"
							:initialValue="this.log_type_text" @item-selected="handleItemSelected" fieldName="log_type">
						</CustomDropDown>
					</div>
					<div class="audit-prvd-select d-inline-block float-start mb-15">
						<CustomDropDown :options="actionDataSource" :defaultSelectedClass="defaultSelectedActionClass"
							:initialValue="this.action_text" @item-selected="handleItemSelected" fieldName="action">
						</CustomDropDown>
					</div>
				</div>
				<div class="h-100">
					<div class="d-flex d-flex flex-column justify-content-between h-100">
						<div class="position-relative">
							<div class="table-responsive auditLogs-table">
								<table class="table table-borderless mb-0">
									<thead>
										<tr>
											<th>DATETIME</th>
											<th>USER</th>
											<th>MODULE</th>
											<th>ACTION TYPE</th>
											<th>DEMOGRAPHIC</th>
											<th>ACTION DETAILS</th>
											<th style="width:45px;"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in auditlogData" :key="index">

											<td class="white-space-pre">{{ $filters.inputDateTime(item.created_at,
												'YYYY-MM-DD hh:mm A') }}</td>
											<td>{{ user_list[item.user_id] }}</td>
											<td>{{ item.log_type }}</td>
											<td>{{ item.action }}</td>
											<td>{{ patient_list[item.patient_id] }}</td>
											<td><span class="max-action-cont" v-html="item.detail_txt"></span></td>
											<td><img src="/images/dots-icon.svg" class="dot_icon" @click="viewLog(item)">
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="audit-pagination-table text-center" v-if="this.lastpage != 1">
							<ul class="page-pagination p-0">
								<li class="preview-page-arrow"><img src="/images/left-arrow-pagen.svg"
										@click="fetchAuditLogData(this.currentPage - 1)" v-if="this.currentPage != 1"></li>
								<li>{{ this.currentPage }}</li>
								<li>/</li>
								<li>{{ this.lastpage }}</li>
								<li class="next-page-arrow"><img src="/images/right-arrow-pagen.svg"
										@click="fetchAuditLogData(this.currentPage + 1)"
										v-if="this.currentPage != this.lastpage"></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade main_popup" id="view-auditLogs" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl view-auditLogs-model">
			<div class="modal-content auto_height_popup">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<ul class="audit-view-list mb-30 p-0">
								<li><span>DATETIME</span>
									<p class="d-inline-block mb-0">{{ $filters.inputDateTime(this.auditlogDetail.created_at,
										'YYYY-MM-DD hh:mm A') }}</p>
								</li>
								<li><span>USER</span>
									<p class="d-inline-block mb-0">{{ user_list[this.auditlogDetail.user_id] }}</p>
								</li>
								<li><span>MODULE</span>
									<p class="d-inline-block mb-0">{{ this.auditlogDetail.log_type }}</p>
								</li>
								<li><span>ACTION TYPE</span>
									<p class="d-inline-block mb-0">{{ this.auditlogDetail.action }}</p>
								</li>
								<li><span>DEMOGRAPHIC</span>
									<p class="d-inline-block mb-0">{{ this.patient_list[this.auditlogDetail.patient_id] }}
									</p>
								</li>
								<li><span>ACTION DETAILS</span>
									<p class="d-inline-block mb-0" v-html="this.auditlogDetail.detail"></p>
								</li>
								<li><span>BEFORE LOG</span>
									<p class="d-inline-block mb-0">{{ this.auditlogDetail.before_log }}</p>
								</li>
								<li><span>AFTER LOG</span>
									<p class="d-inline-block mb-0">{{ this.auditlogDetail.after_log }}</p>
								</li>
								<li><span>IP ADDRESS</span>
									<p class="d-inline-block mb-0">{{ this.auditlogDetail.ip }}</p>
								</li>
							</ul>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn" @click="cancelModel">Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import sidebar from './sidebar.vue';
import $ from "jquery";
import axios from "@/scripts/axios.js";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import DateRangePicker from 'vue3-daterange-picker'


export default {
	data() {
		return {
			minDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
			filter: {
				physician_text: "Select Users",
				user_id: null,
			},
			auditlogData: [],
			auditlogDetail: [],
			patient_list: [],
			user_list: [],
			provider_list: [],
			lastpage: 1,
			currentPage: 1,
			defaultSelectedClass: null,
			defaultSelectedModuleClass: null,
			defaultSelectedActionClass: null,
			log_type_text: "Select Module",
			action_text: "Select Action Type",
			actionDataSource: [
				{ value: "CREATE", title: "CREATE" },
				{ value: "UPDATE", title: "UPDATE" },
				{ value: "DELETE", title: "DELETE" }
			],
			typeDataSource: [
				{ value: "APPOINTMENT", title: "APPOINTMENT" },
				{ value: "DEMOGRAPHIC", title: "DEMOGRAPHIC" },
				{ value: "E-CHART ALLERGIES", title: "E-CHART ALLERGIES" },
				{ value: "E-CHART CONSULTATIONS", title: "E-CHART CONSULTATIONS" },
				{ value: "E-CHART FILES", title: "E-CHART FILES" },
				{ value: "E-CHART IMAGING", title: "E-CHART IMAGING" },
				{ value: "E-CHART LABS", title: "E-CHART LABS" },
				{ value: "E-CHART MEASUREMENTS", title: "E-CHART MEASUREMENTS" },
				{ value: "E-CHART MEDICATIONS", title: "E-CHART MEDICATIONS" },
				{ value: "E-CHART PREVENTIONS", title: "E-CHART PREVENTIONS" },
				{ value: "INBOX", title: "INBOX" },
				{ value: "BILLING", title: "BILLING" },
				{ value: "ADMINISTRATION", title: "ADMINISTRATION" },
			],
			dateRange: {
				startDate: new Date(),
				endDate: new Date()
			},
		}
	},
	components: {
		sidebar,
		CustomDropDown,
		DateRangePicker
	},
	methods: {
		keyupdate(obj) {
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).after(errorElement);
			}
			const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if(obj=='startDatePicker'){
					this.dateRange.startDate = formattedDate;
				}else{
					this.dateRange.endDate = formattedDate;
				}
				const startDate = new Date(this.dateRange.startDate);
				const endDate = new Date(this.dateRange.endDate);

				if (endDate < startDate) {
					this.$filters.moshaToast("End Date should be greater than Start Date", "danger");
					if(obj=='startDatePicker'){
						this.dateRange.startDate = null;
					}else{
						this.dateRange.endDate = null;
					}
					$(`#${obj}`).val("");
				}
			} else {
				if(formattedDate!=''){
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		
		extractDates(dateRange, type) {
			const [startDate, endDate] = dateRange.split(" - ");
			return (type == "start") ? startDate : endDate;
		},
		formatDateRange(date) {
			if (!date) return '';
			// Extract year, month, and day from the date
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');

			// Return the formatted date
			return `${year}/${month}/${day}`;
		},
		getdate() {
			this.fetchAuditLogData()
		},
		changeDateRangePicker() {
			this.getdate();
		},
		fetchAuditLogData(pageNo = 1) {
			if (pageNo <= 1) {
				pageNo = 1
			}
			var dateRange = `${this.formatDateRange(new Date(this.dateRange.startDate))} - ${this.formatDateRange(new Date(this.dateRange.endDate))}`;
			let params = {
				'page': pageNo,
				'date_range': dateRange,
				'filter_user_id': this.filter.user_id,
				'action': this.filter.action,
				'log_type': this.filter.log_type,
			}

			this.$store.state.loader = true;
			axios.post('audit-log', params)
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let responseDetail = response.data.data;
						this.auditlogData = responseDetail.audit_log.data
						this.user_list = responseDetail.user_list
						this.lastpage = responseDetail.audit_log.last_page
						this.currentPage = responseDetail.audit_log.current_page
						this.patient_list = responseDetail.patient_list
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		formatCurrency(value) {
			return value.toFixed(2);
		},
		viewLog(item) {
			this.auditlogDetail = item
			window.$("#view-auditLogs").modal("show")
		},
		cancelModel() {
			window.$("#view-auditLogs").modal("hide")
		},
		getProviderList() {
			axios.post("json_list/provider")
				.then((response) => {
					const providerTempData = [];
					$.each(response.data.data, function (key, detail) {
						providerTempData.push({
							value: detail.user_id,
							title: detail.role_name + " | " + detail.full_name,
						})
					})
					this.provider_list = providerTempData;
				})

		},
		handleItemSelected({ fieldName, item }) {
			this.filter[fieldName] = item.value;
			if (fieldName == 'user_id') {
				this.filter.physician_text = item.title
				this.defaultSelectedClass = 'active'
				this.fetchAuditLogData()
			} else if (fieldName == 'action') {
				this.action_text = item.title
				this.defaultSelectedActionClass = 'active'
				this.fetchAuditLogData()
			} else if (fieldName == 'log_type') {
				this.log_type_text = item.title
				this.defaultSelectedModuleClass = 'active'
				this.fetchAuditLogData()
			}
		},
		openDateRange() {
			this.$refs.daterangepicker.ej2Instances.show();
		}
	},
	mounted() {
		this.getProviderList()
		this.fetchAuditLogData()
		let $this = this;
		$(document).on("click",'.applyBtn', function(){
			$this.changeDateRangePicker();
		});
	},
	computed: {
		ranges() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);

			// Define the start of the week (Sunday) and end of the week (Saturday)
			let thisWeekStart = new Date(today);
			thisWeekStart.setDate(today.getDate() - today.getDay());
			let thisWeekEnd = new Date(thisWeekStart);
			thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

			// Define the start and end of the last week
			let lastWeekStart = new Date(thisWeekStart);
			lastWeekStart.setDate(thisWeekStart.getDate() - 7);
			let lastWeekEnd = new Date(lastWeekStart);
			lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

			// Define the date range for the last 7 days
			let last7DaysStart = new Date(today);
			last7DaysStart.setDate(today.getDate() - 6);

			// Define the date range for the last 30 days
			let last30DaysStart = new Date(today);
			last30DaysStart.setDate(today.getDate() - 29);

			// Define the date range for the last 60 days
			let last60DaysStart = new Date(today);
			last60DaysStart.setDate(today.getDate() - 59);

			// Define the date range for the last 90 days
			let last90DaysStart = new Date(today);
			last90DaysStart.setDate(today.getDate() - 89);

			let range = {
				'Today': [today, today],
				'Yesterday': [yesterday, yesterday],
				'This Week': [thisWeekStart, thisWeekEnd],
				'Last Week': [lastWeekStart, lastWeekEnd],
				'Last 7 Days': [last7DaysStart, today],
				'Last 30 Days': [last30DaysStart, today],
				'Last 60 Days': [last60DaysStart, today],
				'Last 90 Days': [last90DaysStart, today],
			};
			return range;
		}
	},
};
</script>
