<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 px-0">
			<div class="admin-left-content management_settings">
				<div class="row align-items-center m-0">
					<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
						<h1 class="h1-admin_head mb-0">Shift Hours Setting</h1>
					</div>
					<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 text-end">
						<button class="comman_btn crt-user-bttn big_btn_new" @click="createSetting()"
							v-if="this.checkPermission()">Create Setting</button>
					</div>
				</div>
				<div class="table-responsive acct-stng-tbl mt-30">
					<table class="table table-borderless text-center mb-0 ">
						<thead>
							<tr>
								<th style="width: 180px;">USER TYPE</th>
								<th style="width: 222px;">LAST NAME</th>
								<th style="width: 240px;">FIRST NAME</th>
								<th>SHIFT SCHEDULE</th>
								<th>ACTIVITY</th>
								<th style="width: 65px;"></th>
							</tr>
						</thead>
						<tbody v-if="shiftHoursSettingsList.length > 0">
							<tr v-for="(item, index) in this.shiftHoursSettingsList" :key="index">
								<td>{{ item.role_name }}</td>
								<td>{{ item.last_name }}</td>
								<td>{{ item.first_name }}</td>
								<td>{{ item.shift_schedule_start_date }} : {{ item.shift_schedule_end_date }} | {{
									item.day_of_week }}</td>
								<td>{{ (item.is_active) ? 'Active' : 'Inactive' }}</td>
								<td>
									<img src="/images/dots-icon.svg" class="dot_icon"
										@click="viewCreateSettings('shift-hours', item.user_id, item.recurrence_ids)"
										:class="{ 'invisible': (!this.checkPermission() && (item.user_id != this.authUserId)) }">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-end">
					<ul class="page-pagination p-0" :class="{ 'invisible': (shiftHourTotalPages == 0) }">
						<li style="margin-right: 40px;" :class="{ 'disabled': this.shiftHourCurrentPage === 1 }"
							@click="this.shiftHourTotalPages > 1 && this.shiftHourCurrentPage > 1 && getShiftHoursSettingsList(this.shiftHourCurrentPage - 1)">
							<img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="this.shiftHourCurrentPage != 1">
						</li>
						<li>{{ this.shiftHourCurrentPage }}</li>
						<li>/</li>
						<li>{{ this.shiftHourTotalPages || 1 }}</li>
						<li style="margin-left: 40px;"
							:class="{ 'disabled': this.shiftHourCurrentPage == this.shiftHourTotalPages }"
							@click="this.shiftHourTotalPages > 1 && this.shiftHourCurrentPage < this.shiftHourTotalPages && getShiftHoursSettingsList(this.shiftHourCurrentPage + 1)">
							<img src="/images/right-arrow-pagen.svg" class="img-fluid"
								v-if="this.shiftHourCurrentPage != this.shiftHourTotalPages">
						</li>
					</ul>
				</div>
				<div class="d-flex align-items-center mt-2">
					<h1 class="h1-admin_head mb-0">Compensation Management</h1>
				</div>
				<div class="table-responsive acct-stng-tbl mt-30">
					<table class="table table-borderless text-center mb-0 ">
						<thead>
							<tr>
								<th style="width: 180px;">USER TYPE</th>
								<th style="width: 222px;">LAST NAME</th>
								<th style="width: 240px;">FIRST NAME</th>
								<th>SALARY CONFIGURATION</th>
								<th>ACTIVITY</th>
								<th style="width: 65px;"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in this.compensationSettingsList" :key="index">
								<td>{{ item.role_name }}</td>
								<td>{{ item.last_name }}</td>
								<td>{{ item.first_name }}</td>
								<td>{{ item.salary_configuration }}</td>
								<td>{{ (item.is_active) ? 'Active' : 'Inactive' }}</td>
								<td>
									<img src="/images/dots-icon.svg" class="dot_icon"
										@click="viewCreateSettings('compensation-management', item.user_id, [])"
										:class="{ 'invisible': (!this.checkPermission() && (item.user_id != this.authUserId)) }">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="text-end">
					<ul class="page-pagination p-0" :class="{ 'invisible': (compensationTotalPages == 0) }">
						<li style="margin-right: 40px;" :class="{ 'disabled': this.compensationCurrentPage === 1 }"
							@click="this.compensationTotalPages > 1 && this.compensationCurrentPage > 1 && getCompensationSettingsList(this.compensationCurrentPage - 1)">
							<img src="/images/left-arrow-pagen.svg" class="img-fluid"
								v-if="this.compensationCurrentPage != 1">
						</li>
						<li>{{ this.compensationCurrentPage }}</li>
						<li>/</li>
						<li>{{ this.compensationTotalPages || 1 }}</li>
						<li style="margin-left: 40px;"
							:class="{ 'disabled': this.compensationCurrentPage == this.compensationTotalPages }"
							@click="this.compensationTotalPages > 1 && this.compensationCurrentPage < this.compensationTotalPages && getCompensationSettingsList(this.compensationCurrentPage + 1)">
							<img src="/images/right-arrow-pagen.svg" class="img-fluid"
								v-if="this.compensationCurrentPage != this.compensationTotalPages">
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="create-setting" tabindex="-1" style="display: none;" aria-hidden="true"
		ref="createSettingsPop">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content auto_height_popup">
				<div class="modal-body" v-if="createSettingModal">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"
						@click="closePop"></button>
					<div class="box">
						<div>
							<ul class="comn_tab_menu">
								<li :class="{ 'd-none': this.isViewFromCompensation, 'active': this.isShowShiftHour }"
									@click="changeActiveTab($event, 'shift_hour')">Regular Hours</li>
								<li :class="{ 'd-none': this.isViewFromCompensation, 'active': this.isShowSpecialHour }"
									@click="changeActiveTab($event, 'special_hour')">Special Hours</li>
								<li :class="{ 'd-none': this.isViewFromShiftHour, 'active': this.isShowCompensation }"
									@click="changeActiveTab($event, 'compensation')">Compensation Management</li>
							</ul>
							<section class="con_detl">
								<div class="row regular_hours"
									:class="{ 'special_hour': this.isShowSpecialHour, 'composation': this.isShowCompensation }">
									<div class="col-md-12">
										<div class="detail_main_box select_user mb-35">
											<div class="data_label">SELECT USER <small class="asterisksign">*</small></div>
											<div class="data_con" style="padding: 19.5px 0px;"
												v-if="this.form.is_setting_create_or_update == 'update'">{{
													this.form.user_name_role }}</div>
											<CustomDropDown v-else :options="providerUserListData"
												:initialValue="this.form.user_name_role_test"
												@item-selected="handleItemSelected" v-model="form.user_name_role"
												fieldName="provider_list">
											</CustomDropDown>
										</div>
									</div>
									<div class="col-md-6 mb-43" v-if="this.isShowShiftHour">
										<div class="detail_main_box">
											<div class="data_label">START DATE <small class="asterisksign">*</small></div>
											<!-- <div class="data_con"><input type="text" class="form-control" v-model="this.form.shift_schedule_start_date"></div> -->
											<div class="calendar text_calender _datepic">
												<ejs-datepicker class="e-field form-control"
													:value="form.shift_schedule_start_date"
													@change="handleDate($event, 'start', 'shift_schedule_start_datepicker')"
													@blur="handleDate($event, 'start', 'shift_schedule_start_datepicker')"
													v-model="form.shift_schedule_start_date" :showClearButton="false"
													format="yyyy-MM-dd"
													@focus="openDatePicker('shift_schedule_start_datepicker')"
													ref="shift_schedule_start_datepicker"
													id="shift_schedule_start_datepicker" :min="shiftScheduleMinDate"
													:max="shiftScheduleMaxDate" readonly>
												</ejs-datepicker>
											</div>
										</div>
									</div>
									<div class="col-md-6 mb-43" v-if="this.isShowShiftHour">
										<div class="detail_main_box justify-content-end me-1">
											<div class="data_label w-auto mr-70">END DATE <small
													class="asterisksign">*</small></div>
											<!-- <div class="data_con"><input type="text" class="form-control" v-model="this.form.shift_schedule_end_date"></div> -->
											<div class="calendar text_calender _datepic">
												<ejs-datepicker class="e-field form-control"
													:value="form.shift_schedule_end_date"
													@change="handleDate($event, 'end', 'shift_schedule_end_datepicker')"
													@blur="handleDate($event, 'end', 'shift_schedule_end_datepicker')"
													v-model="form.shift_schedule_end_date" :showClearButton="false"
													format="yyyy-MM-dd"
													@focus="openDatePicker('shift_schedule_end_datepicker')"
													ref="shift_schedule_end_datepicker" id="shift_schedule_end_datepicker"
													:min="shiftScheduleMinDate" :max="shiftScheduleMaxDate"
													@keyup="this.keyupdate('shift_schedule_end_datepicker')" readonly>
												</ejs-datepicker>
											</div>
											<div class="close_sign_icon invisible ms-3"><img src="/images/close-icon.svg"
													class="img-fluid"></div>
										</div>
									</div>
									<div class="col-md-12" v-if="this.isShowShiftHour">
										<div class="detail_main_box align-items-sm-start">
											<div class="data_label mt-2">SHIFT SCHEDULE <small
													class="asterisksign">*</small></div>
											<div class="data_con shft_schedule">
												<div :class="{ 'scrl_con': form.regular_hours.length > 6 }">
													<slot v-for="(item, index) in form.regular_hours" :key="index">
														<div class="contn_dx mb-40">
															<input type="hidden" v-model="item.id">
															<div class="mr-37 drop-list-scroll">
																<CustomDropDown :ref="`scheduleDayOfWeek${index}`"
																	:isOpenUpSide="isDropdownOpenUpside(index)"
																	:options="days_opt" @item-selected="handleItemSelected"
																	:fieldName="`week_day_${index}`"
																	:initialValue="item.day_of_week"
																	:isFrom="this.form.is_setting_create_or_update"
																	:dropdownDefaultSelectedClass="dropdownDefaultSelectedClass"
																	v-model="item.day_of_week">
																</CustomDropDown>
															</div>
															<div class="time_picker Selact_drodwn">
																<label for="" class="shift_label">Start Time</label>
																<input type="text" :ref="`scheduleStartTime`"
																	class="form-control focus_none"
																	v-model="item.start_time"
																	@keyup="searchTime('regular_hours_start', index)"
																	@focus="showListOfTime('regular_hours_start', index)">
																<div class="dropdown-div">
																	<div v-if="focusedField === 'regular_hours_start' && focusedIndex === index"
																		class="list">
																		<slot
																			v-for="(time, timeIndex) in filteredStartTimeOptions"
																			:key="timeIndex">
																			<div class="item"
																				@click="onSelectTime('regular_hours_start', index, time)">
																				{{ time }}</div>
																		</slot>
																	</div>
																</div>
															</div>
															<div class="dash_line">-</div>
															<div class="time_picker mr-37 Selact_drodwn">
																<label for="" class="shift_label">End Time</label>
																<input type="text" :ref="`scheduleEndTime`"
																	class="form-control focus_none" v-model="item.end_time"
																	@keyup="searchTime('regular_hours_end', index)"
																	@focus="showListOfTime('regular_hours_end', index)">
																<div class="dropdown-div">
																	<div v-if="focusedField === 'regular_hours_end' && focusedIndex === index"
																		class="list">
																		<slot
																			v-for="(time, timeIndex) in filteredEndTimeOptions"
																			:key="timeIndex">
																			<div class="item"
																				@click="onSelectTime('regular_hours_end', index, time)">
																				{{ time }}</div>
																		</slot>
																	</div>
																</div>
															</div>
															<!-- <div class="week_number"> -->
															<div class="week_number">
																<label for="" class="shift_label">Repeats (Week)</label>
																<!-- <input type="number" class="form-control focus_none" v-model="item.recurrent_week_interval"> -->
																<CustomDropDown :isOpenUpSide="isDropdownOpenUpside(index)"
																	:fieldName="`recurrence_week_interval_${index}`"
																	:initialValue="item.recurrent_week_interval"
																	:options="recurrence_week_interval_opt"
																	@item-selected="handleItemSelected"
																	:isFrom="this.form.is_setting_create_or_update"
																	:dropdownDefaultSelectedClass="dropdownDefaultSelectedClass"
																	v-model="item.recurrent_week_interval">
																</CustomDropDown>
															</div>
															<div :class="{ 'invisible': index == 0 }"
																class="close_sign_icon"
																@click="removeRegularHourObj(index, item.id)">
																<img src="/images/close-icon.svg" class="img-fluid">
															</div>
														</div>
													</slot>
												</div>
												<div class="sticky_plus">
													<div class="plus_sign_row" @click="addRegularHourObj">+</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row special_hour" v-if="this.isShowSpecialHour">
									<div class="col-md-12">
										<div class="detail_main_box align-items-sm-start">
											<div class="data_label mt-2">SPECIAL HOURS <small class="asterisksign">*</small>
											</div>
											<div class="data_con shft_schedule">
												<div :class="{ 'scrl_con': form.special_hours.length > 4 }">
													<slot v-for="(item, index) in form.special_hours" :key="index">
														<div class="contn_dx mb-23">
															<div
																:class="{ 'mr-20': item.is_day_off, 'mr-37': !item.is_day_off }">
																<label class="shift_label invisible"></label>
																<div class="calendar text_calender _datepic">
																	<ejs-datepicker class="e-field form-control"
																		:value="item.date"
																		@change="handleSpecialHourDate($event, 'special_hour_date', index, `special_hours_datepicker_${index}`)"
																		@blur="handleSpecialHourDate($event, 'special_hour_date', index, `special_hours_datepicker_${index}`)"
																		v-model="item.date" :showClearButton="false"
																		format="yyyy-MM-dd"
																		@focus="openDatePicker(`special_hours_datepicker_${index}`)"
																		:ref="`special_hours_datepicker_${index}`"
																		:id="`special_hours_datepicker_${index}`"
																		:min="specialHourMinDate" :max="specialHoursMaxDate"
																		@keyup="this.keyupdate(`special_hours_datepicker_${index}`)"
																		readonly>
																	</ejs-datepicker>
																</div>
															</div>
															<div class="time_picker Selact_drodwn"
																v-if="item.is_day_off === false">
																<label for="" class="shift_label">Start Time</label>
																<input type="text" :ref="`specialHourStartTime`"
																	class="form-control focus_none"
																	v-model="item.start_time"
																	@keyup="searchTime('special_hours_start', index)"
																	@focus="showListOfTime('special_hours_start', index)">
																<div class="dropdown-div">
																	<div v-if="focusedField === 'special_hours_start' && focusedIndex === index"
																		class="list">
																		<slot
																			v-for="(time, timeIndex) in filteredStartTimeOptions"
																			:key="timeIndex">
																			<div class="item"
																				@click="onSelectTime('special_hours_start', index, time)">
																				{{ time }}</div>
																		</slot>
																	</div>
																</div>
															</div>
															<div class="dash_line" v-if="item.is_day_off === false">-</div>
															<div class="time_picker mr-20 Selact_drodwn"
																v-if="item.is_day_off === false">
																<label for="" class="shift_label">End Time</label>
																<input type="text" :ref="`specialHourEndTime`"
																	class="form-control focus_none" v-model="item.end_time"
																	@keyup="searchTime('special_hours_end', index)"
																	@focus="showListOfTime('special_hours_end', index)">
																<div class="dropdown-div">
																	<div v-if="focusedField === 'special_hours_end' && focusedIndex === index"
																		class="list">
																		<slot
																			v-for="(time, timeIndex) in filteredEndTimeOptions"
																			:key="timeIndex">
																			<div class="item"
																				@click="onSelectTime('special_hours_end', index, time)">
																				{{ time }}</div>
																		</slot>
																	</div>
																</div>
															</div>
															<div class="close_sign_icon cursor-pointer"
																@click="removeSpecialHourObj(index, item.id)">
																<img src="/images/close-icon.svg" class="img-fluid">
															</div>
														</div>
														<div class="d-flex align-items-center mb-40 fs-14 fw-600 txt_gry">
															<span class="mr-37">Day Off</span>
															<div class="usr-dtls-switch accs-swtch">
																<label class="switch d-block">
																	<input type="checkbox"
																		:checked="item.is_day_off === true"
																		v-model="item.is_day_off">
																	<span class="sw-slider"></span>
																</label>
															</div>
														</div>
													</slot>
												</div>
												<div class="sticky_plus">
													<div class="plus_sign_row" @click="addSpecialHourObj">+</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row composation" v-if="this.isShowCompensation">
									<div class="col-md-12 mb-50">
										<div class="detail_main_box h-40">
											<div class="d-flex align-items-center">
												<div class="data_label mt-7">SALARY BY HOURLY RATE</div>
												<div class="usr-dtls-switch accs-swtch">
													<label class="switch d-block">
														<input type="checkbox"
															:checked="form.is_active_salary_rate_toggle === true"
															@change="onChangeToggleEvent('salary_rate')">
														<span class="sw-slider"></span>
													</label>
												</div>
												<div class="data_con d-flex align-items-center"
													v-if="form.is_active_salary_rate_toggle === true">
													<div class="edit-fild-nw price-w-dollar position-relative">
														<span class="dollar-sign">$</span>
														<input type="text" placeholder="" class="form-control"
															v-model="form.salary_rate">
													</div>
													<p class="mb-0 fils_value"> / Hour</p>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-12">
										<div class="detail_main_box mb-50 h-40"
											v-if="this.form.user_role == 'Doctor' || this.form.user_role == 'Nurse'">
											<div class="d-flex align-items-center">
												<div class="data_label d-flex align-items-center">SALARY BY CLINIC - DOCTOR
													SPLIT</div>
												<div class="usr-dtls-switch accs-swtch">
													<label class="switch d-block">
														<input type="checkbox"
															:checked="form.is_active_salary_split_toggle === true"
															@change="onChangeToggleEvent('salary_split')">
														<span class="sw-slider"></span>
													</label>
												</div>
												<div class="data_con d-flex align-items-center"
													v-if="form.is_active_salary_split_toggle === true">
													<div class="edit-fild-nw">
														<input type="text" placeholder="" class="form-control"
															v-model="form.salary_split">
													</div>
													<p class="mb-0 fils_value">% to MD</p>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-12 mb-46">
										<div class="detail_main_box">
											<div class="d-flex align-items-center">
												<div class="data_label d-flex align-items-center mt-1">START DATE <small
														class="asterisksign">*</small></div>
												<div class="calendar text_calender _datepic">
													<ejs-datepicker class="e-field form-control" :value="form.start_date"
														@change="handleDate($event, 'compensation-start', 'compensation_start_datepicker')"
														@blur="handleDate($event, 'compensation-start', 'compensation_start_datepicker')"
														v-model="form.start_date" :showClearButton="false"
														format="yyyy-MM-dd"
														@focus="openDatePicker('compensation_start_datepicker')"
														ref="compensation_start_datepicker"
														id="compensation_start_datepicker" :min="compensationMinDate"
														:max="compensationMaxDate"
														@keyup="this.keyupdate('compensation_start_datepicker')" readonly>
													</ejs-datepicker>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-12">
										<div class="detail_main_box">
											<div class="d-flex align-items-center">
												<div class="data_label d-flex align-items-center">END DATE <small
														class="asterisksign">*</small></div>
												<div class="calendar text_calender _datepic">
													<ejs-datepicker class="e-field form-control" :value="form.end_date"
														@change="handleDate($event, 'compensation-end', 'compensation_end_datepicker')"
														@blur="handleDate($event, 'compensation-end', 'compensation_end_datepicker')"
														v-model="form.end_date" :showClearButton="false" format="yyyy-MM-dd"
														@focus="openDatePicker('compensation_end_datepicker')"
														ref="compensation_end_datepicker" id="compensation_end_datepicker"
														:min="compensationMinDate" :max="compensationMaxDate"
														@keyup="this.keyupdate('compensation_end_datepicker')" readonly>
													</ejs-datepicker>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
						<div class="btn_part text-center d-flex justify-content-center align-items-center">
							<button class="comman_brdr_btn big_btn mx40" @click="closePop"> Cancel </button>
							<div class="position-relative">
								<button class="comman_btn big_btn mx40" @click="storeManagementSettings"
									:class="{ 'invisible': (!this.checkPermission() && (this.form.selected_user_id != this.authUserId)) }">Save
									<img src="images/loader.gif" v-if="loader" />
								</button>
								<span class="popup_link ml-20 fs-20 fw-600"
									style="position: absolute;white-space: nowrap;padding: 17px 0px;line-height: normal;"
									@click="seePreview()" v-if="this.isShowShiftHour || this.isShowSpecialHour">
									See Preview
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade main_popup" id="see-preview" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body" v-if="seePreviewModal">
					<button type="button" class="e-info btn-close popup_close z-1" data-bs-dismiss="modal"
						aria-label="Close" @click="reloadScheduler"></button>
					<div class="box regular-hours-calendar">
						<ejs-schedule ref="schedule" :selectedDate="selectedDate" :eventSettings="eventSettings"
							:currentView="currentView" :views="views" :renderCell='onRenderCell'
							@created="handleScheduleCreated" :readonly="true">
							<template v-slot:eventTemplate="{ data }">
								<div class="calendar_data_table">
									<div class="sub_heading">{{ data.Subject }}</div>
									<div v-for="(item, index) in data.Description" :key="index" class="sub_heading">
										{{ item }}
									</div>
								</div>
							</template>
						</ejs-schedule>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue';
import $ from "jquery";
import moment from "moment";
import axios from "@/scripts/axios.js";
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { ScheduleComponent, Month } from '@syncfusion/ej2-vue-schedule';
export default {
	data() {
		return {
			isViewFromShiftHour: false,
			isViewFromCompensation: false,
			focusedField: null,
			focusedIndex: null,
			timeOptions: [],
			filteredStartTimeOptions: [],
			filteredEndTimeOptions: [],
			compensationMinDate: null,
			compensationMaxDate: null,
			shiftScheduleMinDate: null,
			shiftScheduleMaxDate: null,
			specialHourMinDate: null,
			specialHoursMaxDate: null,
			selectedDate: new Date(),
			currentView: 'Month',
			views: ['Month'],
			eventSettings: {
				template: "eventTemplate",
				dataSource: [],
			},
			loader: false,
			userRoles: [{
				0: 'System',
				1: 'Admin',
				2: 'Doctor',
				3: 'Nurse',
				4: 'Staff'
			}],
			isShowShiftHour: true,
			isShowSpecialHour: false,
			isShowCompensation: false,
			recurrence_week_interval_opt: [
				{ value: 1, title: "1" },
				{ value: 2, title: "2" },
				{ value: 3, title: "3" },
				{ value: 4, title: "4" },
				{ value: 5, title: "5" },
			],
			days_opt: [
				{ value: 'Sunday', title: "SUNDAY" },
				{ value: 'Monday', title: "MONDAY" },
				{ value: 'Tuesday', title: "TUESDAY" },
				{ value: 'Wednesday', title: "WEDNESDAY" },
				{ value: 'Thursday', title: "THURSDAY" },
				{ value: 'Friday', title: "FRIDAY" },
				{ value: 'Saturday', title: "SATURDAY" }
			],
			providerUserListData: [],
			shiftHoursSettingsList: [],
			shiftHourTotalPages: 1,
			shiftHourCurrentPage: 1,
			compensationSettingsList: [],
			compensationTotalPages: 1,
			compensationCurrentPage: 1,
			regularHours: {
				day_of_week: 'Select Day',
				start_time: '',
				end_time: '',
				recurrent_week_interval: 1,
			},
			specialHours: {
				id: '',
				date: '',
				start_time: '',
				end_time: '',
				is_day_off: false,
			},
			form: {
				selected_user_id: '',
				user_name_role: '',
				user_role: '',
				user_name_role_test: 'Select Users',
				shift_schedule_start_date: '',
				shift_schedule_end_date: '',
				regular_hours: [{
					id: null,
					day_of_week: 'Select Day',
					start_time: '',
					end_time: '',
					recurrent_week_interval: 1,
				}],
				special_hours: [],
				start_date: '',
				end_date: '',
				is_active_salary_rate_toggle: false,
				is_active_salary_split_toggle: false,
				salary_rate: '',
				salary_split: '',
				is_setting_create_or_update: 'create',
				removed_recurrence_ids: [],
				removed_special_hours_ids: [],
				settings_from: 'create-settings',
			},
			managementCalendarEventData: [],
			authUserId: null,
			authUserRole: '',
			permissionList: [],
			endTimeMin: [],
			specialHoursStartTimes: [],
			specialHoursEndTimeMin: [],
			dropdownDefaultSelectedClass: [],
			createSettingModal: false,
			seePreviewModal: false
		}
	},
	components: {
		sidebar,
		CustomDropDown,
		'ejs-datepicker': DatePickerComponent,
		'ejs-schedule': ScheduleComponent,
	},
	created() {
		this.timeOptions = this.generateTimeOptions();
	},
	provide: {
		schedule: [Month]
	},
	mounted: async function () {
		try {
			this.authUserId = localStorage.getItem("userId");
			this.authUserRole = localStorage.getItem("authUserRole");

			// Await each function to ensure sequential execution
			await this.getPermissionList();
			await this.getShiftHoursSettingsList(this.shiftHourCurrentPage);
			await this.getCompensationSettingsList(this.compensationCurrentPage);

			this.setMinAndMaxDates();

			document.addEventListener('click', this.handleClickOutside);

			var $this = this;
			$(document).on("click", '._datepic', function () {
				const inputField = $(this).find('input');
				const inputId = inputField.attr('id');
				$this.openDatePicker(inputId);
			});

			await this.providerUserList();
		} catch (error) {
			console.error('An error occurred:', error);
		}
	},
	watch: {
		managementCalendarEventData(newData) {
			this.eventSettings = {
				template: "eventTemplate",
				dataSource: newData
			};
		},
		handleClickOutside(event) {
			const popupElement = this.$refs.createSettingsPop;
			if (popupElement && !popupElement.contains(event.target)) {
				this.closePop();
			}
		},
	},
	methods: {
		keyupdate(obj) {
			if ($(`#${obj}-error`)) {
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		handleClickOutside(event) {
			if (this.focusedIndex !== null && (this.focusedField === 'regular_hours_end' || this.focusedField === 'regular_hours_start')) {
				const startInputElement = this.$refs['scheduleStartTime'][this.focusedIndex];
				const endInputElement = this.$refs['scheduleEndTime'][this.focusedIndex];

				if (startInputElement && endInputElement) {
					const isOutsideStartInput = !startInputElement.contains(event.target);
					const isOutsideEndInput = !endInputElement.contains(event.target);

					if (isOutsideStartInput && isOutsideEndInput) {
						this.focusedIndex = null;
						this.focusedField = null;
					}
				}
			}
			if (this.focusedIndex !== null && (this.focusedField === 'special_hours_start' || this.focusedField === 'special_hours_end')) {

				const specialStartInputElement = this.$refs['specialHourStartTime'][this.focusedIndex];
				const specialEndInputElement = this.$refs['specialHourEndTime'][this.focusedIndex];

				if (specialStartInputElement && specialEndInputElement) {
					const isOutsideSpecialStartInput = !specialStartInputElement.contains(event.target);
					const isOutsideSpecialEndInput = !specialEndInputElement.contains(event.target);

					if (isOutsideSpecialStartInput && isOutsideSpecialEndInput) {
						this.focusedIndex = null;
						this.focusedField = null;
					}
				}
			}

		},
		showListOfTime(field, index) {
			this.focusedField = field;
			this.focusedIndex = index;
			this.filteredTimeOptions = this.timeOptions;

			this.focusedField = field;
			this.focusedIndex = index;
			if (field === 'special_hours_start') {
				this.filteredStartTimeOptions = this.timeOptions;
			} else if (field === 'special_hours_end') {
				this.filteredEndTimeOptions = this.getFilteredEndTimeOptions('special_hours', index);
			} else if (field === 'regular_hours_start') {
				this.filteredStartTimeOptions = this.timeOptions;
			} else if (field === 'regular_hours_end') {
				this.filteredEndTimeOptions = this.getFilteredEndTimeOptions('regular_hours', index);
			}
		},
		getFilteredEndTimeOptions(field, index) {
			if (field == 'regular_hours') {
				const startTime = this.form.regular_hours[index].start_time;
				const startTimeIndex = this.timeOptions.findIndex(time => time === startTime);
				return startTimeIndex !== -1 ? this.timeOptions.slice(startTimeIndex + 1) : this.timeOptions;
			}
			if (field == 'special_hours') {
				const startTime = this.form.special_hours[index].start_time;
				const startTimeIndex = this.timeOptions.findIndex(time => time === startTime);
				return startTimeIndex !== -1 ? this.timeOptions.slice(startTimeIndex + 1) : this.timeOptions;
			}
		},
		searchTime(field, index) {
			let searchTerm;
			if (field === 'special_hours_start') {
				searchTerm = this.form.special_hours[index].start_time.toLowerCase();
				this.filteredStartTimeOptions = this.timeOptions.filter(time =>
					time.toLowerCase().startsWith(searchTerm)
				);
			} else if (field === 'special_hours_end') {
				searchTerm = this.form.special_hours[index].end_time.toLowerCase();
				const availableEndTimeOptions = this.getFilteredEndTimeOptions('special_hours', index);
				this.filteredEndTimeOptions = availableEndTimeOptions.filter(time =>
					time.toLowerCase().startsWith(searchTerm)
				);
			} else if (field === 'regular_hours_start') {
				searchTerm = this.form.regular_hours[index].start_time.toLowerCase();
				this.filteredStartTimeOptions = this.timeOptions.filter(time =>
					time.toLowerCase().startsWith(searchTerm)
				);
			} else if (field === 'regular_hours_end') {
				searchTerm = this.form.regular_hours[index].end_time.toLowerCase();
				const availableEndTimeOptions = this.getFilteredEndTimeOptions('regular_hours', index);
				this.filteredEndTimeOptions = availableEndTimeOptions.filter(time =>
					time.toLowerCase().startsWith(searchTerm)
				);
			}
		},
		onSelectTime(field, index, time) {
			if (field === 'special_hours_start') {
				this.form.special_hours[index].start_time = time;
				this.filteredEndTimeOptions = this.getFilteredEndTimeOptions('special_hours', index);
			} else if (field === 'special_hours_end') {
				this.form.special_hours[index].end_time = time;
			}
			if (field === 'regular_hours_start') {
				this.form.regular_hours[index].start_time = time;
				this.filteredEndTimeOptions = this.getFilteredEndTimeOptions('regular_hours', index);
			} else if (field === 'regular_hours_end') {
				this.form.regular_hours[index].end_time = time;
			}
			this.filteredStartTimeOptions = [];
			this.filteredEndTimeOptions = [];
			this.focusedField = null;
			this.focusedIndex = null;
		},
		generateTimeOptions() {
			const times = [];
			let currentDate = new Date(1970, 0, 1, 0, 0, 0);

			while (currentDate.getDate() === 1) {
				let hours = currentDate.getHours();
				let minutes = currentDate.getMinutes();
				let period = hours < 12 ? 'AM' : 'PM';

				if (hours === 0) {
					hours = 12;
				} else if (hours > 12) {
					hours = hours - 12;
				}

				let formattedHours = hours < 10 ? hours : hours;
				let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

				times.push(`${formattedHours}:${formattedMinutes} ${period}`);

				currentDate.setMinutes(currentDate.getMinutes() + 5);
			}
			return times;
		},
		setMinAndMaxDates() {
			const today = new Date();
			const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

			this.shiftScheduleMinDate = today;
			this.shiftScheduleMaxDate = nextYear;
			this.specialHourMinDate = today;
			this.specialHoursMaxDate = nextYear;
			this.compensationMinDate = today;
			this.specialHoursMaxDate = nextYear;
		},
		reloadScheduler() {
			this.selectedDate = new Date();
		},
		handleScheduleCreated() {
			const scheduleElement = this.$refs.schedule.$el;

			const prevButton = scheduleElement.querySelector('.e-prev .e-tbar-btn');
			const nextButton = scheduleElement.querySelector('.e-next .e-tbar-btn');
			if (prevButton || nextButton) {
				prevButton.addEventListener('click', this.handlePrevNextButtonClick);
				nextButton.addEventListener('click', this.handlePrevNextButtonClick);
			}
		},
		handlePrevNextButtonClick() {
			this.$refs.schedule.ej2Instances.refreshEvents();
			setTimeout(() => {
				if ($(document).find('e-other-month')) {
					$('.e-other-month').html('');
				}
				$('td').has('.e-appointment-wrapper').addClass('td-has-event');
			}, 500);
		},
		viewHrReport() {
			this.$router.push(
				`/administration/hr-analysis-report`
			);
		},
		isCurrentMonth(dateStr) {
			const currentDate = new Date();
			const currentMonth = currentDate.getMonth();
			const currentYear = currentDate.getFullYear();

			const dateToCheck = new Date(dateStr);
			const checkMonth = dateToCheck.getMonth();
			const checkYear = dateToCheck.getFullYear();

			return currentMonth === checkMonth && currentYear === checkYear;
		},
		onRenderCell(args) {
			if (args.elementType === "monthCells") {
				setTimeout(() => {
					$(args.element).find('.e-appointment-wrapper .e-appointment:not(:last)').remove();
					if (!this.isCurrentMonth(args.date)) {
						if ($(args.element).hasClass('e-other-month')) {
							$(args.element).html('');
						}
					}
				}, 2000);
				setTimeout(() => {
					if ($(document).find('e-other-month')) {
						$('.e-other-month').html('');
					}
				}, 500);
				setTimeout(() => {
					if ($(document).find('e-other-month')) {
						$('.e-other-month').html('');
					}
					$('td').has('.e-appointment-wrapper').addClass('td-has-event');
				}, 300);
			}
		},
		async getPermissionList() {
			axios.post('user/get-permission')
				.then((response) => {
					if (response.status == 200) {
						this.permissionList = response.data.data
					} else {
						this.permissionList = []
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.permissionList = []
					} else {
						this.permissionList = []
					}
				});
		},
		checkPermission() {
			if (this.permissionList && this.permissionList.administration_access) {
				return true
			} else {
				return false
			}
		},
		isDropdownOpenUpside(index) {
			return index > 5;
		},
		onChangeToggleEvent(type) {
			if (type === 'salary_rate') {
				this.form.is_active_salary_rate_toggle = !this.form.is_active_salary_rate_toggle;
			} else if (type === 'salary_split') {
				this.form.is_active_salary_split_toggle = !this.form.is_active_salary_split_toggle;
			}
		},
		delay(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		async seePreview() {
			if (this.form.regular_hours.length > 0 || this.form.special_hours.length > 0) {
				if (!this.validateFromData('from-see-preview')) {
					return false;
				}
				else {
					this.prepareEvents();
				}
			}
			this.$store.state.loader = true;
			await this.delay(1000);
			this.seePreviewModal = true;
			setTimeout(() => {
				if ($(document).find('e-other-month')) {
					$('.e-other-month').html('');
				}
			}, 500);
			window.$("#see-preview").modal("show");
			setTimeout(() => {
				if ($(document).find('e-other-month')) {
					$('.e-other-month').html('');
				}
				$('td').has('.e-appointment-wrapper').addClass('td-has-event');
				this.$refs.schedule.ej2Instances.refreshEvents();
				this.$store.state.loader = false;
			}, 300);
		},
		checkForConflicts() {
			const timeFormat = 'h:mm A';
			const parseTime = (time) => moment(time, timeFormat);
			const scheduleMap = {};

			for (const schedule of this.form.regular_hours) {
				const { day_of_week, start_time, end_time } = schedule;
				if (!scheduleMap[day_of_week]) {
					scheduleMap[day_of_week] = [];
				}
				const startTime = parseTime(start_time);
				const endTime = parseTime(end_time);
				for (const existing of scheduleMap[day_of_week]) {
					const existingStartTime = parseTime(existing.start_time);
					const existingEndTime = parseTime(existing.end_time);
					if ((startTime.isBefore(existingEndTime) && endTime.isAfter(existingStartTime)) ||
						(startTime.isSame(existingStartTime) && endTime.isSame(existingEndTime))) {
						return true;
					}
				}
				scheduleMap[day_of_week].push({ start_time, end_time });
			}
			return false;
		},
		convertTo24HourFormat(time) {
			const [timeString, modifier] = time.split(' ');
			let [hours, minutes] = timeString.split(':');

			// Convert hours to string for padStart to work
			hours = hours.toString();

			if (hours === '12') {
				hours = '00';
			}

			if (modifier === 'PM') {
				hours = (parseInt(hours, 10) + 12).toString();
			}

			return `${hours.padStart(2, '0')}:${minutes}`;
		},
		validateFromData(from) {
			if (this.form.selected_user_id === '') {
				this.$filters.moshaToast('Required, To select user.', "error");
				return false;
			}

			let skipRegularHoursValidation = false;
			if (from == 'store' || from == 'from-see-preview') {
				/* Regular Hours Validation */
				if (this.form.shift_schedule_start_date === '' && this.form.shift_schedule_end_date === '') {
					const checkFirstItem = this.form.regular_hours[0];
					if ((checkFirstItem.day_of_week === 'Select Day' || checkFirstItem.day_of_week === '') && checkFirstItem.start_time === '' && checkFirstItem.end_time === '') {
						skipRegularHoursValidation = true;
					}
				}
				if (this.form.regular_hours.length > 0) {
					if (!skipRegularHoursValidation) {
						if (this.form.shift_schedule_start_date === '') {
							this.$filters.moshaToast('Start date selection is required.', "error");
							return false;
						}

						if (this.form.shift_schedule_end_date === '') {
							this.$filters.moshaToast('End date selection is required.', "error");
							return false;
						}

						if (this.form.shift_schedule_end_date != '') {
							const startDate = moment(this.form.shift_schedule_start_date);
							const endDate = moment(this.form.shift_schedule_end_date);

							if (!endDate.isAfter(startDate)) {
								this.$filters.moshaToast('End date must be greater than start date.', "error");
								return false;
							}

							const maxEndDate = startDate.clone().add(1, 'years');
							if (endDate.isAfter(maxEndDate)) {
								this.$filters.moshaToast('End date must be within one year from start date.', 'error');
								return false;
							}
						}
						for (let i = 0; i < this.form.regular_hours.length; i++) {
							const item = this.form.regular_hours[i];
							if (item.day_of_week === 'Select Day' || item.day_of_week === '') {
								this.$filters.moshaToast('Required to select day of week', "error");
								return false;
							}
							if (item.start_time === '') {
								this.$filters.moshaToast('Required to select start time', "error");
								return false;
							}
							if (item.end_time === '') {
								this.$filters.moshaToast('Required to select end time', "error");
								return false;
							}
							const startTime24 = this.convertTo24HourFormat(item.start_time);
							const endTime24 = this.convertTo24HourFormat(item.end_time);

							if (endTime24 <= startTime24) {
								this.$filters.moshaToast('End time must be greater than start time', "error");
								return false;
							}
							if (item.recurrent_week_interval < 1 || item.recurrent_week_interval > 5) {
								this.$filters.moshaToast('Required to enter week interval between 1 to 5', "error");
								return false;
							}
						}

						const hasConflict = this.checkForConflicts();
						if (hasConflict === true) {
							this.$filters.moshaToast('According to day of week selected time is conflict.', "error");
							return false;
						}
					}
				}

				/* Special Hours Validation */
				if (this.form.special_hours.length > 0) {
					for (let i = 0; i < this.form.special_hours.length; i++) {
						const item = this.form.special_hours[i];
						if (item.date == '') {
							this.$filters.moshaToast('Required to select date on special hour', "error");
							return false;
						}
						if (item.is_day_off === false) {
							if (item.start_time === '') {
								this.$filters.moshaToast('Required to select start time on special hour', "error");
								return false;
							}
							if (item.end_time === '') {
								this.$filters.moshaToast('Required to select end time on special hour', "error");
								return false;
							}
							const startTime24 = this.convertTo24HourFormat(item.start_time);
							const endTime24 = this.convertTo24HourFormat(item.end_time);

							if (endTime24 <= startTime24) {
								this.$filters.moshaToast('End time must be greater than start time on special hour', "error");
								return false;
							}
						}
					}
				}
				else {
					this.form.special_hours = [];
				}
			}

			let skipCompensation = false;
			if (from != 'from-see-preview') {
				/* Compensation validation */
				const startDate = this.form.start_date;
				const endDate = this.form.end_date;
				if (this.form.is_active_salary_rate_toggle) {
					if (this.form.salary_rate == '' || this.form.salary_rate == '$') {
						this.$filters.moshaToast('Required to input salary rate', "error");
						return false;
					}

					if (startDate === '' || endDate === '') {
						if (startDate === '') {
							this.$filters.moshaToast('Required to select start date.', "error");
							return false;
						}
						if (endDate == '') {
							this.$filters.moshaToast('Required to select end date.', "error");
							return false;
						}
						const compensationStartDate = moment(startDate);
						const compensationEndDate = moment(endDate);

						if (!compensationEndDate.isAfter(compensationStartDate)) {
							this.$filters.moshaToast('End date must be greater than start date.', "error");
							return false;
						}

						const maxEndDate = compensationStartDate.clone().add(1, 'years');
						if (compensationEndDate.isAfter(maxEndDate)) {
							this.$filters.moshaToast('End date must be within one year from start date.', 'error');
							return false;
						}
					}
				}
				else {
					this.form.salary_rate = '';
				}
				if (this.form.is_active_salary_split_toggle) {
					if (this.form.salary_split == '') {
						this.$filters.moshaToast('Required to input split rate', "error");
						return false;
					}
					if (isNaN(this.form.salary_split)) {
						this.$filters.moshaToast('Salary split must be a number', "error");
						return false;
					}
					const numericValue = Number(this.form.salary_split);
					if (numericValue < 1 || numericValue > 99) {
						this.$filters.moshaToast('Salary split must be between 1 and 99', "error");
						return false;
					}

					if (startDate === '' || endDate === '') {
						if (startDate === '') {
							this.$filters.moshaToast('Required to select start date.', "error");
							return false;
						}
						if (endDate == '') {
							this.$filters.moshaToast('Required to select end date.', "error");
							return false;
						}
					}
					const compensationStartDate = moment(startDate);
					const compensationEndDate = moment(endDate);

					if (!compensationEndDate.isAfter(compensationStartDate)) {
						this.$filters.moshaToast('End date must be greater than start date.', "error");
						return false;
					}

					const maxEndDate = compensationStartDate.clone().add(1, 'years');
					if (compensationEndDate.isAfter(maxEndDate)) {
						this.$filters.moshaToast('End date must be within one year from start date.', 'error');
						return false;
					}
				}
				else {
					this.form.salary_split = '';
				}
				if (startDate || endDate) {
					const compensationStartDate = moment(startDate);
					const compensationEndDate = moment(endDate);

					if (!compensationEndDate.isAfter(compensationStartDate)) {
						this.$filters.moshaToast('End date must be greater than start date.', "error");
						return false;
					}

					const maxEndDate = compensationStartDate.clone().add(1, 'years');
					if (compensationEndDate.isAfter(maxEndDate)) {
						this.$filters.moshaToast('End date must be within one year from start date.', 'error');
						return false;
					}

					if (!this.form.is_active_salary_split_toggle && !this.form.is_active_salary_rate_toggle) {
						this.$filters.moshaToast('Required, To set value of salary rate or split rate.', "error");
						return false;
					}
				}
				if (!this.form.is_active_salary_split_toggle && !this.form.is_active_salary_rate_toggle && startDate === '' && endDate === '') {
					this.form.salary_split = '';
					this.form.salary_split = '';
					skipCompensation = true;
				}
			}

			/* Common validation */
			if (skipRegularHoursValidation && this.form.special_hours.length === 0 && skipCompensation) {
				this.$filters.moshaToast('Required, To fill-up any one settings.', "error");
				return false;
			}
			if (skipRegularHoursValidation) {
				if (from === 'store') {
					this.form.regular_hours = [];
				}
				else {
					this.form.regular_hours.push({
						id: null,
						day_of_week: 'Select Day',
						start_time: '',
						end_time: '',
						recurrent_week_interval: 1,
					});
				}
			}
			return true;
		},
		generateDates(start_date, end_date, day_of_week, interval) {
			const startDate = new Date(start_date);
			const endDate = new Date(end_date);
			const dayOfWeekMap = {
				"SUNDAY": 0,
				"MONDAY": 1,
				"TUESDAY": 2,
				"WEDNESDAY": 3,
				"THURSDAY": 4,
				"FRIDAY": 5,
				"SATURDAY": 6,
			};

			function getDayDifference(targetDay, startDay) {
				return (targetDay + 7 - startDay) % 7;
			}

			function addDays(date, days) {
				const result = new Date(date);
				result.setDate(result.getDate() + days);
				return result;
			}

			const targetDay = dayOfWeekMap[day_of_week];
			let currentDate = new Date(startDate);

			const dayDifference = getDayDifference(targetDay, currentDate.getDay());
			currentDate = addDays(currentDate, dayDifference);

			let dates = [];
			while (currentDate <= endDate) {
				dates.push(currentDate.toISOString().split('T')[0]); // Only the date part
				currentDate = addDays(currentDate, interval * 7);
			}

			return dates;
		},
		parseTime(time) {
			const [timePart, modifier] = time.split(' ');
			let [hours, minutes] = timePart.split(':').map(Number);
			if (modifier === 'PM' && hours !== 12) {
				hours += 12;
			}
			if (modifier === 'AM' && hours === 12) {
				hours = 0;
			}
			return [hours, minutes, 0];
		},
		prepareEvents() {
			this.reloadScheduler();
			if (this.form.regular_hours.length > 0) {
				const checkFirstItem = this.form.regular_hours[0];
				if ((checkFirstItem.day_of_week !== 'Select Day' || checkFirstItem.day_of_week !== '') && checkFirstItem.start_time !== '' && checkFirstItem.end_time !== '') {
					let startDate = new Date(this.form.shift_schedule_start_date);
					const year = startDate.getFullYear();
					const month = startDate.getMonth();
					this.selectedDate = new Date(year, month);
				}
			}
			if (this.form.regular_hours.length == 0 && this.form.special_hours.length > 0) {
				let startDate = new Date(this.form.special_hours[0].date);
				const year = startDate.getFullYear();
				const month = startDate.getMonth();
				this.selectedDate = new Date(year, month);
			}

			const eventsMap = new Map();
			const specialEventsMap = new Map();
			const dayOffDates = new Set();
			/* Regular Hours - merge events on date & prepare time slots */
			this.form.regular_hours.forEach(recurrence => {
				const datesArr = this.generateDates(this.form.shift_schedule_start_date, this.form.shift_schedule_end_date, recurrence.day_of_week, recurrence.recurrent_week_interval);
				datesArr.forEach(date => {
					const timeSlot = `${recurrence.start_time} - ${recurrence.end_time}`;
					if (eventsMap.has(date)) {
						eventsMap.get(date).time_slots.push(timeSlot);
					} else {
						const event = {
							date: date,
							start_time: recurrence.start_time,
							end_time: recurrence.end_time,
							time_slots: [timeSlot]
						};
						eventsMap.set(date, event);
					}
				});
			});

			/* Special Hours - merge events on date & prepare time slots */
			this.form.special_hours.forEach(specialEvent => {
				if (specialEvent.is_day_off) {
					dayOffDates.add(specialEvent.date);
				} else {
					const timeSlot = `${specialEvent.start_time} - ${specialEvent.end_time}`;
					if (specialEventsMap.has(specialEvent.date)) {
						specialEventsMap.get(specialEvent.date).time_slots.push(timeSlot);
					} else {
						const specialHourEvent = {
							date: specialEvent.date,
							start_time: specialEvent.start_time,
							end_time: specialEvent.end_time,
							time_slots: [timeSlot]
						};
						specialEventsMap.set(specialEvent.date, specialHourEvent);
					}
				}
			});

			// Remove events that have is_day_off
			dayOffDates.forEach(date => {
				eventsMap.delete(date);
				specialEventsMap.delete(date);
			});

			// Remove dates from eventsMap that exist in specialEventsMap
			specialEventsMap.forEach((value, date) => {
				if (eventsMap.has(date)) {
					eventsMap.delete(date);
				}
			});

			const mergedEventsMap = new Map([...eventsMap, ...specialEventsMap]);
			const mergedEventsArray = Array.from(mergedEventsMap.values());
			mergedEventsArray.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			let ejsEvents = [];
			mergedEventsArray.forEach((event, index) => {
				let [year, month, day] = event.date.split('-').map(Number);
				month -= 1;

				let descriptionArray = [];
				event.time_slots.forEach(slot => {
					descriptionArray.push(slot);
				});

				let [startHour, startMinute, startSecond] = this.parseTime(event.start_time);
				let [endHour, endMinute, endSecond] = this.parseTime(event.end_time);
				let subject = index === 0 ? 'START DATE' : '';

				ejsEvents.push({
					// Id: event.Id,
					Subject: subject,
					Description: descriptionArray,
					StartTime: new Date(year, month, day, startHour, startMinute, startSecond),
					EndTime: new Date(year, month, day, endHour, endMinute, endSecond),
					Date: `${year}-${month + 1}-${day}`
				});
			});

			this.managementCalendarEventData = ejsEvents;
		},
		storeManagementSettings() {
			this.loader = true;
			if (!this.validateFromData('store')) {
				this.loader = false;
				return false;
			}
			else {
				axios.post('administration/management-setting-store', this.form)
					.then((response) => {
						this.loader = false;
						if (response.status == 200) {
							// const data = response.data.data;
							window.$("#create-setting").modal("hide");
							this.$filters.moshaToast("Management Settings has been updated successfully", "success");
							this.closePop();
							this.getShiftHoursSettingsList(1);
							this.getCompensationSettingsList(1);
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.$store.state.loader = false;
						this.loader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							console.log(Object.values(error.response.data.data).join(', '))
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					});
			}
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(event, type, obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if (type === 'start') {
					this.form.shift_schedule_start_date = moment(sanitizedInput).format('YYYY-MM-DD');
				} else if (type === 'end') {
					this.form.shift_schedule_end_date = moment(sanitizedInput).format('YYYY-MM-DD');
				} else if (type === 'compensation-end') {
					this.form.end_date = moment(sanitizedInput).format('YYYY-MM-DD');
				} else if (type === 'compensation-start') {
					this.form.start_date = moment(sanitizedInput).format('YYYY-MM-DD');
				}
			} else {
				if (formattedDate != '') {
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		handleSpecialHourDate(event, type, index, obj) {
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				if (type === 'special_hour_date') {
					this.form.special_hours[index].date = moment(sanitizedInput).format('YYYY-MM-DD');
				}
			} else {
				if (formattedDate != '') {
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		openDatePicker(type) {
			if (type.startsWith('special_hours_datepicker_')) {
				const index = type.split('_').pop();
				const refName = `special_hours_datepicker_${index}`;
				var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
				schObj.show();
			} else {
				var schObj1 = document.querySelector(`#${type}`).ej2_instances[0];
				schObj1.show();
			}
		},
		addRegularHourObj() {
			this.form.regular_hours.push({
				id: null,
				day_of_week: 'Select Day',
				start_time: '',
				end_time: '',
				recurrent_week_interval: 1,
			});
		},
		addSpecialHourObj() {
			this.form.special_hours.push({
				id: null,
				date: '',
				start_time: '',
				end_time: '',
				is_day_off: false,
			});
		},
		removeRegularHourObj(index, recurrenceId) {
			if (index > 0 && index < this.form.regular_hours.length) {
				this.form.regular_hours.splice(index, 1);
				this.form.removed_recurrence_ids.push(recurrenceId);
			}
		},
		removeSpecialHourObj(index, eventId) {
			if (index >= 0 && index < this.form.special_hours.length) {
				this.form.special_hours.splice(index, 1);
				this.form.removed_special_hours_ids.push(eventId);
			}
		},
		closePop() {
			window.$("#create-setting").modal("hide");
			this.form.selected_user_id = '';
			this.form.user_name_role = '';
			this.form.user_role = '';
			this.form.user_name_role_test = 'Select Users';
			this.form.shift_schedule_start_date = '';
			this.form.shift_schedule_end_date = '';
			this.form.regular_hours = [{
				id: null,
				day_of_week: 'Select Day',
				start_time: '',
				end_time: '',
				recurrent_week_interval: 1,
			}];
			this.form.special_hours = [];
			this.form.is_active_salary_rate_toggle = false;
			this.form.is_active_salary_split_toggle = false;
			this.form.start_date = '';
			this.form.end_date = '';
			this.form.salary_rate = '';
			this.form.salary_split = '';
			this.isShowShiftHour = false;
			this.isShowSpecialHour = false;
			this.isShowCompensation = false;
			this.managementCalendarEventData = [];
			this.eventSettings.dataSource = [];
			this.form.is_setting_create_or_update = 'create';
			this.form.removed_recurrence_ids = [];
			this.form.removed_special_hours_ids = [];
			this.isViewFromShiftHour = false;
			this.isViewFromCompensation = false;
			this.dropdownDefaultSelectedClass = [];
			if (this.$refs.schedule && this.$refs.schedule.ej2Instances) {
				this.$refs.schedule.ej2Instances.refreshEvents();
			}
			this.form.settings_from = 'create-settings';
		},
		viewCreateSettings(type, userId, recurrenceIds) {
			if (type === 'shift-hours') {
				this.isViewFromShiftHour = true;
				this.form.settings_from = type;
			}
			if (type === 'compensation-management') {
				this.isViewFromCompensation = true;
				this.form.settings_from = type;
			}
			this.createSettingModal = true;
			this.getCalendarEvents(type, userId, recurrenceIds);
			this.form.selected_user_id = userId;
			this.$store.state.loader = true;
			axios.post('administration/management-setting-retrieve', { settings_type: type, selected_user_id: userId, recurrence_ids: recurrenceIds })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						const data = response.data.data;
						this.form.is_setting_create_or_update = 'update';
						/* Regular Hours Settings Data */
						this.form.regular_hours = data.regular_hours;
						if (this.form.regular_hours.length > 0) {
							this.form.shift_schedule_start_date = data.regular_hours[0].shift_schedule_start_date;
							this.form.shift_schedule_end_date = data.regular_hours[0].shift_schedule_end_date;
							this.form.user_name_role = data.regular_hours[0].user_name_role;
							this.form.user_role = (data.regular_hours[0].user_name_role).split(' | ')[0].trim();
						}

						/* Special Hours Settings Data */
						this.form.special_hours = data.special_hours;

						/* Compensation Settings Data */
						if (data.compensation_management.length > 0) {
							this.form.user_name_role = data.compensation_management[0].user_name_role;
							this.form.user_role = (data.compensation_management[0].user_name_role).split(' | ')[0].trim();

							this.form.start_date = data.compensation_management[0].start_date;
							this.form.end_date = data.compensation_management[0].end_date;

							this.form.salary_rate = data.compensation_management[0].salary_rate;
							this.form.salary_split = data.compensation_management[0].salary_split;

							this.form.is_active_salary_rate_toggle = (this.form.salary_rate !== null) ? true : false;
							this.form.is_active_salary_split_toggle = (this.form.salary_split !== null) ? true : false;
						}
						if (type === 'shift-hours') {
							this.isShowShiftHour = true;
							this.isShowSpecialHour = false;
							this.isShowCompensation = false;
						}
						else if (type === 'compensation-management') {
							this.isShowCompensation = true;
							this.isShowShiftHour = false;
							this.isShowSpecialHour = false;
						}
						window.$("#create-setting").modal("show");
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		getCalendarEvents(type, userId, recurrenceIds) {
			axios.post('administration/preview-calendar-events', { settings_type: type, selected_user_id: userId, recurrence_ids: recurrenceIds })
				.then((response) => {
					if (response.status == 200) {
						const data = response.data.data;
						let ejsEvents = [];
						if (data.length > 0) {
							const startDate = new Date(data[0].Date);
							const year = startDate.getFullYear();
							const month = startDate.getMonth();
							this.selectedDate = new Date(year, month);

							data.forEach(event => {
								let [year, month, day] = event.Date.split('-').map(Number);
								month -= 1;

								let [startHour, startMinute, startSecond] = event.start_time.split(':').map(Number);
								let [endHour, endMinute, endSecond] = event.end_time.split(':').map(Number);

								let descriptionArray = [];

								event.time_slots.forEach(slot => {
									descriptionArray.push(slot);
								});

								ejsEvents.push({
									Id: event.Id,
									Subject: `${event.Subject}`,
									Description: descriptionArray,
									StartTime: new Date(year, month, day, startHour, startMinute, startSecond),
									EndTime: new Date(year, month, day, endHour, endMinute, endSecond),
									Date: `${year}-${month + 1}-${day}`
								});
							});
						}
						this.managementCalendarEventData = ejsEvents;
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		createSetting() {
			this.isShowShiftHour = true;
			this.createSettingModal = true;
			window.$("#create-setting").modal("show")
		},
		changeActiveTab(event, type) {
			event.stopPropagation();
			this.isShowShiftHour = false
			this.isShowSpecialHour = false
			this.isShowCompensation = false
			if (type == 'shift_hour') {
				this.isShowShiftHour = true
			} else if (type == 'special_hour') {
				this.isShowSpecialHour = true
			} else if (type == 'compensation') {
				this.isShowCompensation = true
			}
		},
		async providerUserList() {
			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};
			axios.post("json_list/provider", { 'type': '' }, headers)
				.then((response) => {
					const tempUserData = []
					$.each(response.data.data, function (key, detail) {
						if (localStorage.getItem("userId") != detail.user_id) {
							let fullName = detail.full_name
							tempUserData.push({
								'value': detail.user_id,
								'title': detail.role_name + ' | ' + fullName.toUpperCase(),
							});
						}
					});
					this.providerUserListData = tempUserData;
				})
		},
		async getShiftHoursSettingsList(currentPage) {
			this.$store.state.loader = true;
			axios.post('administration/shift-hours-list', { 'current_page': currentPage })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						this.shiftHoursSettingsList = response.data.data.shift_hours_settings_list;
						this.shiftHourTotalPages = response.data.data.total_pages;
						this.shiftHourCurrentPage = response.data.data.current_page;
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		async getCompensationSettingsList(currentPage) {
			this.$store.state.loader = true;
			axios.post('administration/compensation-list', { 'current_page': currentPage })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						this.compensationSettingsList = response.data.data.compensation_settings_list;
						this.compensationTotalPages = response.data.data.total_pages;
						this.compensationCurrentPage = response.data.data.current_page;
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		handleItemSelected(values) {
			if (values.fieldName == 'provider_list' && (values.item.value && values.item.title)) {
				this.form.selected_user_id = values.item.value;
				// this.form.user_name_role = values.item.title;
				this.form.user_name_role_test = values.item.title;
				this.form.user_role = (values.item.title).split(' | ')[0].trim();
			}
			if (values.fieldName.includes('week_day') && (values.item.value && values.item.title)) {
				const index = values.fieldName.split('_')[2];
				this.form.regular_hours[index].day_of_week = values.item.title;
				this.updateDropdownDefaultSelectedClass(values.fieldName);
			}
			if (values.fieldName.includes('recurrence_week_interval') && (values.item.value && values.item.title)) {
				const index = values.fieldName.split('_')[3];
				this.form.regular_hours[index].recurrent_week_interval = values.item.value;
				this.updateDropdownDefaultSelectedClass(values.fieldName);
			}
		},
		updateDropdownDefaultSelectedClass(fieldName) {
			this.dropdownDefaultSelectedClass.push(fieldName);
		},
	},
};
</script>